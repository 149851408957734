<template>
  <div class="container">

    <div class="headerbar" v-loading="loading">
      <el-row :gutter="12">
        <el-col :span="8">
          <el-card shadow="hover">
            <h4>今日交易金额</h4>
            <div class="desc">
              <p><span style="color:#2ed573">{{dashboard.today_success_total}}</span> 元</p>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover">
            <h4>今日交易笔数</h4>
            <div class="desc">
              <p><span style="color:#ff7f50">{{dashboard.today_success_count}}</span> 笔</p>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover">
            <h4>今日开通商户数量</h4>
            <div class="desc">
              <p style="color:#1e90ff;font-size: 18px;font-weight: 600;">{{dashboard.today_merchant_count}}</p>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <br>
      <el-row :gutter="12">
        <el-col :span="8">
          <el-card shadow="hover">
            <h4>昨日交易金额</h4>
            <div class="desc">
              <p><span style="color:#2ed573">{{dashboard.yesterday_success_total}}</span> 元</p>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover">
            <h4>昨日交易笔数</h4>
            <div class="desc">
              <p><span style="color:#ff7f50">{{dashboard.yesterday_success_count}}</span> 笔</p>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="hover">
            <h4>昨日开通商户数量</h4>
            <div class="desc">
              <p style="color:#1e90ff;font-size: 18px;font-weight: 600;">{{dashboard.yesterday_merchant_count}}</p>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div> 
  </div>
</template>

<style lang="less" scoped>
.container {
  width: 100%;
  // height: 80vh;
  // background-image: url("../../assets/image/backage.png");
  // background-size: cover;

  .headerbar {
    padding: 12px 0;
    .desc {
      font-size: 14px;

      span {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
</style>


<script setup>
import { ref, getCurrentInstance, onMounted } from "vue"
import { ElMessage, ElNotification } from 'element-plus'


const { appContext } = getCurrentInstance()

const loading = ref(true)

const dashboard = ref({
  today_success_count: 0,
  today_success_total: 0,
  today_merchant_count: 0,
  yesterday_success_total: 0,
  yesterday_success_count: 0,
  yesterday_merchant_count: 0,
})

const queryData = () => {
  appContext.config.globalProperties.$api.statistics().then(r => {
    if (r.status == 200) {
      dashboard.value = r.data.data
    } else {
      ElMessage.error('数据查询失败,请稍后重试')
    }
    loading.value = false
  }).catch(err => {
    ElMessage.error(err.message)
    ElNotification({
      title: "错误信息",
      message: err.response.data.message,
      type: 'error',
    })
    loading.value = false
  })
}

onMounted(() => {
  queryData()
})

</script>